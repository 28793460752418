import { render, staticRenderFns } from "./handleKMS.vue?vue&type=template&id=1506f47e&scoped=true"
import script from "./handleKMS.vue?vue&type=script&lang=js"
export * from "./handleKMS.vue?vue&type=script&lang=js"
import style0 from "./handleKMS.vue?vue&type=style&index=0&id=1506f47e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1506f47e",
  null
  
)

export default component.exports