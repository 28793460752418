<template>
<div>
  <div class="demo_cent">
    <h1 style="text-align: center">齐道各系统DEMO体验</h1>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">牛店长</h1>
      <div class="demo_ndz_cent">
      <div v-for="(item,index) in ndz">
        <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
        <div class="demo_ndz_name">{{item.name}}</div>

      </div>
      </div>
    </div>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">齐道CRM</h1>
      <div class="demo_ndz_cent">
        <div v-for="(item,index) in crm">
          <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
          <div class="demo_ndz_name">{{item.name}}</div>
          <div>
            地址：
            <a :href="item.address" v-if="item.address" target="_blank"> 点击跳转</a>
            <span v-else>无</span></div>
        </div>
      </div>
    </div>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">思念</h1>
      <div class="demo_ndz_cent">
        <div v-for="(item,index) in sn">
          <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
          <div class="demo_ndz_name">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">颐海国际</h1>
      <div class="demo_ndz_cent">
        <div v-for="(item,index) in yh">
          <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
          <div class="demo_ndz_name">{{item.name}}</div>
          <div v-if="item.address">
            地址：
            <a :href="item.address" target="_blank"> 点击跳转</a>
          </div>
        </div>
      </div>
    </div>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">天麦然</h1>
      <div class="demo_ndz_cent">
        <div v-for="(item,index) in tmr">
          <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
          <div class="demo_ndz_name">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="demo_ndz">
      <h1 style="border-left: red solid 5px;padding: 0 0 0 20px">加多宝</h1>
      <div class="demo_ndz_cent">
        <div v-for="(item,index) in jdb">
          <img :src="item.qrcode ||require('../../../public/image/demo/nodata.png')">
          <div class="demo_ndz_name">{{item.name}}</div>
          <div v-if="item.address">
            地址：
            <a :href="item.address" target="_blank"> 点击跳转</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "demo",
  data(){
    return{
      ndz:[
        {
          name:'牛店长后台管理系统',
          account:'admin',
          password:'123456',
          address:'https://ndz.chaona.top/user/login',
        },
        {
          name:'牛店长业务员端',
          account:'cexiaqiang',
          password:'123456',
          qrcode:require('../../../public/image/demo/ndzjy.png'),
        },
        {
          name:'牛店长门店端',
          account:'xiaqiangmen',
          password:'123456',
          qrcode:require('../../../public/image/demo/ndzsh.png')
        },
        {
          name:'牛店长消费者端',
          account:'xiaqiangmen',
          password:'123456',
          qrcode:require('../../../public/image/demo/ndzyh.png')
        },
      ],
      crm:[
        {
          name:'齐道CRM后台管理系统',
          account:'admin',
          password:'123456',
          address:'https://crm.qidaokj.cn/',
        },
        {
          name:'Dms经销商商城端',
          account:'test0001',
          password:'fy123456',
          address:'https://dms.qidaokj.cn',
        },
        {
          name:'Dms商城小程序',
          account:'test0001',
          password:'fy123456',
          qrcode:require('../../../public/image/demo/crmjxs.png')
        },
      ],
      sn:[
        {
          name:'思念DMS管理端测试环境',
          account:'Lixiaojian',
          password:'Sn123456',
          // address:'http://171.8.66.199:40652/sndms/customer/customer',
        },
        {
          name:'思念DMS PC商城测试环境',
          account:'Lixiaojian',
          password:'Sn123456',
          // address:'http://171.8.66.199:40652/logIn',
        },
        {
          name:'思念食品客户服务系统体验版',
          account:'Lixiaojian',
          password:'Sn123456',
          qrcode:require('../../../public/image/demo/snkhfw.png')
        },
      ],
      yh:[
        {
          name:'颐海CRM测试系统',
          account:'admin',
          password:'crm001',
          address:'https://crmapptest.yihchina.net/eisp/loginController.do?login#',
        },
        {
          name:'颐海PC商城测试环境',
          account:'100010',
          password:'crm002',
          address:'https://crmapptest.yihchina.net/dms/login',
        },
        {
          name:'颐海小程序测试版',
          account:'100010',
          password:'crm002',
          addressNo: '#小程序://颐海微平台/IknML7i68DR7Ubo',
          qrcode:require('../../../public/image/demo/yhdms.jpg')
        },
        {
          name:'颐海MDS测试系统',
          account:'admin',
          password:'123456',
          // address: 'https://mdsqas.yihchina.com/user/login?redirect=%2F'
        },
        {
          name:'颐海MDS测试小程序',
          account:'100010',
          password:'crm002',
          addressNo: '#小程序://颐海MDS/4Qcieh6LqFbKklx'
        },
      ],
      tmr:[
        {
          name:'天麦然TPM测试系统',
          account:'admin',
          password:'tmr@123456',
          // address:'https://tpmdev.wudeli.com',
        },
        {
          name:'天麦然SFA小程序测试版',
          account:'admin',
          password:'tmr@123456',
          addressNo: '#小程序://天麦然SFA/gX9YYYfyDsmegTi'
        }
      ],
      jdb:[
        {
          name:'加多宝陈列管理平台',
          account:'admin',
          password:'qdcrm001',
          address:'https://tpmtest.jdb.cn/user/login',
        },
        {
          name:'加多宝商户会员',
          account:'admin',
          password:'tmr@123456',
          addressNo: '无',
          qrcode:require('../../../public/image/demo/jdbshhy.jpg')
        },
        {
          name:'加多宝营销系统',
          account:'admin',
          password:'tmr@123456',
          addressNo: '无',
          qrcode:require('../../../public/image/demo/jdbyxxt.jpg')
        },
        {
          name:'加多宝渠道合伙人',
          account:'admin',
          password:'tmr@123456',
          addressNo: '无',
          qrcode:require('../../../public/image/demo/jdbqdhhr.jpg')
        },
        {
          name:'加多宝合作伙伴',
          account:'admin',
          password:'tmr@123456',
          addressNo: '无',
          qrcode:require('../../../public/image/demo/jdbhzhb.jpg')
        }
      ]
    }
  }
}
</script>

<style scoped>
.demo_cent{
  /*background: #00BAAD;*/
  /*background-image: url("../../../public/image/CarouselFigure4.jpeg");*/
  /*border: 1px solid red;*/
  width: 80%;
  margin: auto auto 50px;
}
.demo_ndz_cent{
  display: flex;
}
.demo_ndz_cent>div{
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.demo_ndz_cent img{
  width: 200px;
  height: 220px;
}
.demo_ndz_pan{
  text-align: center;
}
.demo_ndz_name{
  color: #00daff;
}
</style>
